import React, { useState } from 'react';
import PageLayout from 'components/layouts/page/page.layout';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useLocation } from '@reach/router';
import { accountOptOutRoutine } from 'state/account/account.routines';
import { useDispatch } from 'react-redux';
import { OptOutPayload } from 'state/account/account.services';

const NotFound = ({ data }: { data: GatsbyTypes.UnsubscribeDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const [hasUnsubscribed, setHasUnsubscribed] = useState(false);
    const [hasSentUnsubscribe, setHasSentUnsubscribe] = useState(false);
    const endpointId = urlParams.get('EndpointId');
    const channelType = urlParams.get('ChannelType');
    const messageType = urlParams.get('MessageType');
    const attributes = urlParams.get('Attributes.EPostPatientNum');

    // TEST URL http://localhost:8000/unsubscribe?EndpointId=tested@medimpactdirect.com&ChannelType=EMAIL&MessageType=Marketing&Attributes.EPostPatientNum=323820

    const handleUnsubscribe = () => {
        const submitValues: OptOutPayload = {
            communicationValue: String(endpointId),
            communicationType: channelType === 'EMAIL' ? 'Email' : 'SMS', // {Phone=1,SMS=2,Email=3, Push=4,Unknown}
            notificationType: String(messageType), // {PatientAuthorization=1, RefillReminder=2,OrderShipped=3,Marketing=4,None}
            epostPatientNum: String(attributes)
        };

        dispatch(
            accountOptOutRoutine.trigger({
                ...submitValues,
                onSuccess: () => {
                    setHasUnsubscribed(true);
                },
                onFailure: () => {
                    navigate('/404');
                }
            })
        );
    };

    if (typeof window !== 'undefined') {
        // If there are not the required URL parameters, redirect to the 404 page
        if (!endpointId || !channelType || !messageType || !attributes) {
            navigate('/404');
            return null;
        } else {
            // Send the parameters to the API
            if (!hasSentUnsubscribe) {
                setHasSentUnsubscribe(true);
                handleUnsubscribe();
            }
            if (hasUnsubscribed) {
                return <PageLayout metaData={{ nodeTitle: t('pages.unsubscribe.nodeTitle') }}></PageLayout>;
            } else {
                return null;
            }
        }
    } else {
        return null;
    }
};

export default NotFound;

export const query = graphql`
    query UnsubscribeData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
